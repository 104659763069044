import React from 'react'
import Project from '../components/Project'
import { initializeApp } from "firebase/app";
import { Firestore, getFirestore, collection, getDocs, query, DocumentData } from "firebase/firestore";

interface IState {
    projects: DocumentData[]
}

class Projects extends React.Component<any, IState> {

    private firestore!: Firestore

    constructor(props: any) {
        super(props)
        
        const firebaseConfig = {
            apiKey: "AIzaSyCEhy_bDdFwU0OgeJmwimk-3fPK_MPHaMc",
            authDomain: "liam-kiwi.firebaseapp.com",
            projectId: "liam-kiwi",
            storageBucket: "liam-kiwi.appspot.com",
            messagingSenderId: "768060102342",
            appId: "1:768060102342:web:dcdbd64f21d63f52c9e9c7",
            measurementId: "G-FZBZC70W20"
        };

        const app = initializeApp(firebaseConfig);
        this.firestore = getFirestore(app);

        this.state = {
            projects: [],
        }
        this.getProjects()
    }

    private async getProjects() {
        const dbQuery = query(collection(this.firestore, "projects"));
        const snapshot = await getDocs(dbQuery);
        const docs = snapshot.docs.map((doc) => doc.data())
        this.setState({
            projects: docs,
        })
    }

    render() {
        var projects = this.state.projects.map(function (project) {
            return (
                <Project
                    icon={project['emoji']}
                    name={project['title']}
                    explainer={project['description']} 
                    playLink={project['store_links']['play']}
                    appleLink={project['store_links']['apple']}
                    testflightLink={project['store_links']['testflight']}
                />
            );
        });

        return (
            <div className="App">
                <nav>
                    <a href="/">&lt; back</a>
                </nav>
                <header className="Projects-view">
                    { projects }
                </header>
            </div>
        )
    }
}

export default Projects