import testflightBadge from '../assets/testflight-badge.png';

interface ProjectProps {
    icon: string | URL;
    name: string;
    explainer: string;
    playLink?: string;
    appleLink?: string;
    testflightLink?: string;
}

export default function Project({icon, name, explainer, playLink, appleLink, testflightLink}: ProjectProps) {
    let iconEl;
    if (icon instanceof URL) {
        iconEl = (<img src={icon.toString()} />)
    } else {
        iconEl = (<h2 style={{fontSize: 100, margin: 0}}>{icon}</h2>)
    }

    return (
        <div className="Project">
            {iconEl}
            <h2>{name}</h2>
            <p dangerouslySetInnerHTML={{ __html: explainer }} />
            <div className="row">
                { playLink ? (<a href={playLink} target="_blank"><img id="play-badge" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>) : (<p/>) }
                { appleLink ? (<a href={appleLink} target="_blank"><img id="apple-badge" alt='Download on the App Store' src='https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg'/></a>) : (<p/>) }
                { testflightLink ? (<a href={testflightLink} target="_blank"><img id="testflight-badge" alt='Available on Testflight' src={testflightBadge}/></a>) : (<p/>) }
            </div>
        </div>
    )
}